import urljoin from "url-join";

/**
 * 拼接路由为路径
 * @param currentPath 当前路由地址
 * @param prefix 前缀
 * @description
 * 如果字符串为 / 开头的绝对路径，则不添加prefix
 * 否则添加prefix
 */
export const concatPath = (currentPath: string, prefix: string = '/'): string => {
    const pre = /^\/.*?/ig.test(prefix) ? prefix : '/'+prefix;
    let path = urljoin(pre, currentPath);
    if(/^\/.*?/ig.test(currentPath)) {
        path = currentPath;
    }

    return path;
}
