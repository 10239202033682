import React, { Component } from "react";
import { Row, Col, Card, Icon, Upload, Button, Progress } from "antd";
import { getQiniuToken } from "api/utils";
import { RcFile } from "antd/lib/upload";
import * as qiniu from 'qiniu-js';
import { getUniqueFileName } from "utils/unique-file-name";
import urljoin from "url-join";
import { uploadBookStore as pStore } from "../stores/upload-book.store";
import { observer } from "mobx-react";

export interface IBooksProps {
    
}

interface IBookState {
    uploadToken: string;
    uploadFile?: RcFile;
}

const SAVE_PATH = 'userbooks/';

@observer
class UploadBooks extends Component<IBooksProps, IBookState> {

    public state: IBookState = {
        uploadToken: '',
        uploadFile: undefined,
    }

    public async componentDidMount() {
        // TODO: 提取获取token组件
        const { uploadToken } = await getQiniuToken();
        this.setState({ uploadToken });
    }

    public handleUpload = () => {
        console.log(this.state.uploadFile);
        const { uploadFile, uploadToken } = this.state;
        if ( uploadFile && !!uploadToken ) {
            const fileName = getUniqueFileName(uploadFile.name);
            const observable = qiniu.upload(uploadFile, urljoin(SAVE_PATH, fileName) ,uploadToken, {}, {});
            observable.subscribe({
                next(res){
                    pStore.uploadProgress = res.total.percent;
                },
                error(err){
                    console.log('error', err);
                }, 
                complete(res){
                    console.log('complete', res);
                    /**
                     * hash: "ltu7XjOU_rPIA_R5uX7Zt51_jWLM"
                     * key: "userbooks/5_FfBCgWka5F0gE6.pdf"
                     */
                }
            })
        }
    }

    public render() {
        return <div className="inner-card">
            <Card>
                <Row type="flex">
                    <Col sm={24} md={4}>
                        <Upload
                            beforeUpload={ (file: RcFile, FileList: RcFile[]) => { 
                                this.setState({ uploadFile: file })
                                return false;
                            }}
                            accept=".pdf"
                            style={{width: '100%'}}
                            multiple={ false }
                        >
                            <Button>
                                <Icon type="upload" /> 选择文件
                            </Button>
                        </Upload>
                        <Button
                            type="primary"
                            onClick={this.handleUpload}
                            >
                                点击上传
                        </Button>
                        {
                            pStore.uploadProgress > 0 &&
                            <Progress percent={ Math.ceil(pStore.uploadProgress) } />
                        }

                    </Col>
                </Row>
            </Card>
        </div>;
    }
}
 
export default UploadBooks;