import React, { Component } from 'react';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
import { observer, inject } from 'mobx-react';
import { Status } from 'stores/status';
import { concatPath } from 'helpers/concat-path';
import './index.scss';
import { IRouterItem } from 'constants/router';
import { screenSize } from 'utils/screen';
import classNames from 'classnames';
const { Sider } = Layout;
const { SubMenu } = Menu;

interface IProps extends RouteComponentProps {
    menus: IRouterItem[];
    status?: Status;
}

const getItem = (item: IRouterItem, prefix='/') => {

    const path = concatPath(item.value, prefix);
    return (
        <Menu.Item
            key={path}
        >
            <NavLink to={ path }>
                <Icon type={item.icon} />
                <span className="nav-text">{item.name}</span>
            </NavLink>
        </Menu.Item>
    )
};

@inject('status')
@observer
class Index extends Component<IProps> {

    public render() {
        const { REACT_APP_VERSION } = process.env;
        const { menus, status, history } = this.props;
        const collapseSideNav = status ? status.collapseSideNav : true;
        const siderCls = classNames('sider', { mobile: screenSize === "mobile" });
        return (
            <Sider
                collapsed={ collapseSideNav }
                className={ siderCls }
                width={255}
            >
                <div className="logo" />
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    selectedKeys={[history.location.pathname]}
                    theme="dark"
                >
                    {
                        menus.map(menu => {
                            if ( menu.children && menu.children.filter(v => !v.hide).length > 0 ) {
                                return (
                                    <SubMenu
                                        key={ menu.value }
                                        title={
                                            <>
                                                <Icon type={ menu.icon } />
                                                <span className="nav-text">{ menu.name }</span>
                                            </>
                                        }
                                    >
                                        {
                                            menu.children && menu.children.map(v => !v.hide && getItem(v, menu.value))
                                        }
                                    </SubMenu>
                                );
                            }

                            return getItem(menu);

                        })
                    }
                </Menu>
                <div className="version">版本 v { REACT_APP_VERSION }</div>
            </Sider>
        );
    }
}

export default withRouter(Index);