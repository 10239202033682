import React from 'react';
import '../style/default.scss';

function NotFound() {
    return (
        <div className="error-page __not-found">
            <img src="/resources/not-found.png" alt="405 not found" />
            <h2>404</h2>
            <p>抱歉，你访问的页面不存在。</p>
        </div>
    );
}

export default NotFound;