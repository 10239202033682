import './index.scss';
import App from 'components/app';
import React from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn';
import ReactDOM from 'react-dom';
import * as store from 'stores';
import { Provider } from 'mobx-react';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import zh_CN from 'antd/es/locale-provider/zh_CN';

// Moment 本地化
moment.locale('zh-cn');

ReactDOM.render(
    <ConfigProvider locale={zh_CN}>
        <Provider { ...store }>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </ConfigProvider>
, document.getElementById('root'));
