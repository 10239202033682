import { observable, action } from 'mobx';
import { screenSize } from 'utils/screen';

const isMobile = screenSize === 'mobile';

export type IArrowBack = () => void;

export class Status {
    @observable public collapseSideNav: boolean = isMobile ? true : false;
    @observable public headerArrowBack: IArrowBack | boolean = false;
    @observable public headerTitle: string | React.ReactNode = '';

    @action public toggleStatus() {
        this.collapseSideNav = !this.collapseSideNav;
    }
}

export const status = new Status();
