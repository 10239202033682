import { RouteConfig } from "react-router-config";
import { MediaRouter } from "pages/media/router";
import { ServerConfigRouter } from "pages/server/router";
import { ResourcesRouter } from "pages/resources/router";

export interface IRouterItem extends RouteConfig {
    name: string;
    value: string;
    icon?: string;
    children?: IRouterItem[];
    hide?: boolean;
    showBack?: boolean;
}

export const routerConfigs: IRouterItem[] = [
    { name: '消息总览', value: 'overview', icon: 'profile' },
    ...ResourcesRouter,
    ...MediaRouter,    
    ...ServerConfigRouter,
];