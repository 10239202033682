import React, { Component } from "react";
import { Card, Empty } from "antd";

export interface IBooksProps {
    
}

 
class Books extends Component<IBooksProps> {
    public render() { 
        return <div className="inner-card">
            <Card>
                <Empty description="暂无书籍" />
            </Card>
        </div>;
    }
}
 
export default Books;