import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Status } from "stores/status";
import { Icon, Card } from "antd";
import './index.scss';
import { withRouter, RouteComponentProps } from "react-router";
import { getflatNav } from "helpers/flat-router";
export interface IProps extends RouteComponentProps {
    status?: Status;
}
const flatNav = getflatNav();

@inject("status")
@observer
class AppHeader extends Component<IProps> {
    public render() {
        const { status, history } = this.props;
        const  { location: { pathname } } = history;
        const routerConfig = flatNav.find(v => v.path === pathname );
        return (
            <Card className="app-header">
                <span className="icons" onClick={ () => status && status.toggleStatus() }>
                    {status && status.collapseSideNav ? (
                        <Icon type='menu-unfold' />
                    ) : (
                        <Icon type='menu-fold' />
                    )}
                </span>
                {
                    (routerConfig && routerConfig.showBack) &&
                    <Icon
                        type="arrow-left"
                        className="icons __back"
                        onClick={ this.props.history.goBack }
                    />
                }
                <b className="title">
                    { routerConfig && routerConfig.name }
                </b>
            </Card>
        );
    }
}

export default withRouter(AppHeader);
