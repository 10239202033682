import axios from 'axios';
import urlJoin from 'url-join';

const { REACT_APP_SERVER = '', REACT_APP_URI = '' } = process.env;

const instance = axios.create({
  // 超时时间
  baseURL: urlJoin(REACT_APP_SERVER, REACT_APP_URI),
  timeout: 10000,
  responseType: "json",
  // 响应前处理
  transformResponse: responseData => {
    // TODO: 与后端协定成功信息判断
    // const {success,message} = JSON.parse(responseData);
    // if(!success) Message.error(message);
    return responseData;
  },
});

// 响应拦截
instance.interceptors.response.use((response) => {
    const { data }=response;
    return data;
  }, (error) => {
  
    // const {status, statusText}=error.response;
    // Message.error(`${status}-${statusText}`);
  
    return Promise.reject(error);
  });

export default instance;
export const http = instance;