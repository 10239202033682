import "./index.scss";
import React, { Component } from "react";
import { Layout } from "antd";
import Sider from "components/appSider";
import { routerConfigs } from "constants/router";
import { renderRoutes } from "react-router-config";
import AppHeader from 'components/appHeader';
import { getFlatRouter } from "helpers/flat-router";
import { NotFound } from "pages/error";
import { inject, observer } from "mobx-react";
import { Status } from "stores/status";
import { screenSize } from "utils/screen";
import classNames from "classnames";

const { Content } = Layout;

interface IProps {
    status?: Status;
}

@inject('status')
@observer
class App extends Component<IProps> {
    public render() {
        const { status } = this.props;
        const collapseSideNav = status ? status.collapseSideNav : true;
        const isMobie = screenSize === "mobile";
        const layoutCls = classNames({ 'safe-with': !collapseSideNav && isMobie });
        return (
            <Layout className="app" id='app'>
                <Sider menus={routerConfigs} />
                <Layout className={ layoutCls }>
                    <AppHeader />
                    <Content>
                        { renderRoutes(getFlatRouter({ otherwise: NotFound })) }
                    </Content>
                    {
                        (!collapseSideNav && isMobie) &&
                        <div
                            className="mask"
                            onClick={ () => status && status.toggleStatus() }
                        />
                    }
                </Layout>
            </Layout>
        );
    }
}

export default App;
