import { observable, action } from "mobx";
import { promiseStatus } from "common/enums/promiseStatus";
import { getServerConfig, updateServerConfig } from "api/server";
import { message } from "antd";

export class SsrConfigService {
    @observable public configInfo: server.IServerConfigData = { address: '', port: '', success: false };
    @observable public configInfoStatus: promiseStatus = promiseStatus.none;
    @observable public updateStatus: promiseStatus = promiseStatus.none;
    @observable public userPass = 'ZSY';

    @action public async getConfigInfo() {
        this.configInfoStatus = promiseStatus.pendding;
        try {
            this.configInfo = await getServerConfig(this.userPass);
            this.configInfoStatus = promiseStatus.reslove;
        } catch(e) {
            this.configInfoStatus = promiseStatus.reject;
        }
    }

    @action public async updatePort() {
        this.updateStatus = promiseStatus.pendding;
        try {
            const { msg, success } = await updateServerConfig('123');
            this.updateStatus = promiseStatus.reslove;

            if ( success ) {
                this.getConfigInfo();
                message.success(msg);
            } else {
                message.error(msg);
            }
        } catch(e) {
            message.error('更新失败！');
            this.updateStatus = promiseStatus.reject;
        }

    }

}

export const ssrConfigService  = new SsrConfigService();