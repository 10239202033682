import { IRouterItem } from "constants/router";
import { SsrConfig } from ".";

export const ServerConfigRouter: IRouterItem[] = [
    {
        name: '服务器管理', value: 'server', icon: 'cloud-server',
        children: [
            { name: '科学上网', value: 'new-plan', icon: 'rocket', component: SsrConfig },
        ],
    },
];