import { routerConfigs, IRouterItem } from "constants/router";
import { concatPath } from "helpers/concat-path";
import { RouteConfig, RouteConfigComponentProps } from "react-router-config";

export interface IFlatNav {
    name: string;
    path: string;
    showBack?: boolean;
}

export interface IFlatRouterConfig {
    otherwise: React.ComponentType<RouteConfigComponentProps<any>> | React.ComponentType;
}

/**
 * 获取非缓存的NAV
 */
export const getflatNav = (): IFlatNav[] => {
    return flat(routerConfigs);
}

export const getFlatRouter = (config?: IFlatRouterConfig): RouteConfig[] => {
    let flatRoutes = flatRouter(routerConfigs);
    if( config ) {
        flatRoutes.push({ path: '*', component: config.otherwise });
    }

    return flatRoutes;
} 

/**
 * 递归扁平化路由地址
 * @param menus 目录列表
 * @param prefix 前缀符号
 */
const flat = (menus: IRouterItem[], prefix: string= '/'): IFlatNav[] => {
    let flatted: IFlatNav[] = [];
    for ( let i=0; i<menus.length; i++ ) {
        const { name, children, value, showBack  } = menus[i];
        const path = concatPath(value, prefix);
        flatted.push({ name, path, showBack });
        if ( children ) {
            flatted = flatted.concat(flat(children, path));
        }
    }
    return flatted;
}

const flatRouter = (menus: IRouterItem[], prefix: string= '/'): RouteConfig[] => {
    let flatted: RouteConfig[] = [];
    for ( let i=0; i<menus.length; i++ ) {
        let { children, value, component, exact } = menus[i];
        const path = concatPath(value, prefix);
        if ( exact === undefined ) { exact = true };
        if ( component ) {
            flatted.push({ path, component, exact });
        }
        if ( children ) {
            flatted = flatted.concat(flatRouter(children, path));
        }
    }
    return flatted;
}
