import React, { Component } from "react";
import { Card, Button, message } from "antd";
import './ssr-config.scss';
import classNames from "classnames";
import { screenSize } from "utils/screen";
import Meta from "antd/lib/card/Meta";
import { observer } from "mobx-react";
import { ssrConfigService as pService } from "../services/ssr-config.service";
import { promiseStatus } from "common/enums/promiseStatus";
import CopyToClipboard from "react-copy-to-clipboard";
const isMobile = screenSize === 'mobile';

export interface IProps {
    
}
 
@observer
class SsrConfig extends Component<IProps> {

    public async componentDidMount() {
        pService.getConfigInfo();
    }
    public render() {
        const ssrConfigCls = classNames('inner-card ssr-config', { 'mobile': !isMobile });
        const addressEle = <>
            <span className="address">{pService.configInfo.address.substr(0, 20)}...</span>
            <CopyToClipboard
                text={ pService.configInfo.address }
                onCopy={ ()=> message.success('复制成功！') }
            >
                <Button type="link">点击复制</Button>
            </CopyToClipboard>
        </>
        return <div className={ ssrConfigCls }>
                <Card
                    className="config-card"
                    hoverable
                    loading={ pService.configInfoStatus !== promiseStatus.reslove }
                    cover={<img alt="qr-code" src={`http://ssr.thisjs.com/qr.php?pass=ZSY&_=${Math.ceil(Math.random() * 100) +100}`} />}
                >
                    <Meta
                        title={`最新端口：${pService.configInfo.port}`}
                        description= { addressEle }
                    />
                </Card>
                <Button
                    type="primary"
                    className="update-port"
                    onClick={ () => pService.updatePort() }
                    disabled={ pService.configInfoStatus !== promiseStatus.reslove 
                                || 
                                pService.updateStatus === promiseStatus.pendding
                            }
                >更新端口</Button>
            </div>
    }
}
 
export default SsrConfig;