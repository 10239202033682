import { IRouterItem } from "constants/router";
import { Books, UploadBooks } from ".";

export const ResourcesRouter: IRouterItem[] = [
    {
        name: '资源管理', value: 'resources', icon: 'read',
        children: [
            { name: '书籍资源', value: 'books', icon: 'file-pdf', component: Books },
            { name: '上传书籍', value: 'upload-books', icon: 'file-pdf', component: UploadBooks, hide: true },
        ],
    },
];