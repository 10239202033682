import { IRouterItem } from "constants/router";

export const MediaRouter: IRouterItem[] = [
    {
        name: '影音资源', value: 'media', icon: 'video-camera',
        children: [
            { name: '在线会员', value: 'vip', icon: 'crown' },
            { name: '电影资源', value: 'movie', icon: 'youtube' },
        ],
    },
];